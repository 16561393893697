import tw from 'tailwind-styled-components';
import { Title, Text as Paragraph } from '../../../../components/Typography';

type IGridAuthorable = {
  $total: number;
  $current: number;
  $isRegularGrid: boolean | null;
};

export const GridAuthorable = tw.div`
  grid
  grid-cols-4
  gap-20
`;

export const GridAuthorableItem = tw.div<IGridAuthorable>`
  col-span-4
  ${(p) => (p.$total === 1 ? 'lg:col-span-4' : '')}
  ${(p) => (p.$total === 2 ? 'lg:col-span-2' : '')}
  ${(p) => (p.$total === 3 && (p.$current === 0 || p.$current === 1) ? 'lg:col-span-2' : '')}
  ${(p) => (p.$total === 3 && p.$current === 2 ? 'lg:col-span-4' : '')}
  ${(p) => (p.$total > 3 && (p.$current === 0 || p.$current === 1) ? 'lg:col-span-1' : '')}
  ${(p) =>
    p.$total > 3 && p.$current === 2 && !p.$isRegularGrid ? 'lg:col-span-2 lg:row-span-2' : ''}
  ${(p) => (p.$total > 3 && p.$current === 3 ? 'lg:col-span-2' : '')}
  ${(p) => (p.$total === 5 && p.$current === 4 ? 'lg:col-span-4' : '')}
  ${(p) => (p.$total > 5 && p.$current > 3 ? 'lg:col-span-2' : '')}
  ${(p) => (p.$isRegularGrid ? 'lg:col-span-2 lg:row-span-1' : '')}
`;

export const GridContent = tw.div`
  text-center
  lg:mb-30
  sm:mb-5
`;
export const SubHeading = tw(Paragraph)`
  mt-10
  mb-2.5
  text-darkgray
  opacity-70
`;
export const Heading = tw(Title)`
  mb-5
`;
