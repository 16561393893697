import { useEffect, useState } from 'react';

import { AuthorableContentCard } from 'components/AuthorableContentCard';
import { Card } from '../../../../components/Cards';
import { Grid, GridRow } from '../../../../components/Grid';
import { LiquidButton } from '../../../../components/LiquidButton';
import { GridAuthorable, GridAuthorableItem, GridContent, Heading, SubHeading } from './styles';

import { COLORS } from 'styles/constants';
import type { IGenericGridAuthorable } from './types';

const MOBILE_CARD_HEIGHT = 438;
const DESKTOP_CARD_HEIGHT = 500;

export const GenericGridAuthorable = ({
  heading,
  isRegularGrid = false,
  subHeading,
  cards = [],
  initialCount = 6,
  labels = {
    results: 'results',
    resultsSeen: '%0 of %1 results seen',
    sortBy: 'Sort By',
    filters: 'Filters',
    moreResults: 'Show more',
    sortNewest: 'Newest',
    sortAlpha: 'Order (A-Z)',
  },
  trackingEvents,
  host,
  recipeCarouselMarkUp,
}: IGenericGridAuthorable) => {
  const [visibleCount, setVisibleCount] = useState<number>(() =>
    cards.length > initialCount ? initialCount : cards.length,
  );
  const [isMobile, setMobile] = useState(false);
  const [cardHeight, setCardHeight] = useState<number>(DESKTOP_CARD_HEIGHT);
  let isOdd = false;
  useEffect(() => {
    const windowWidth = window.innerWidth;
    setMobile(!!(windowWidth <= 640));
    if (windowWidth <= 320) {
      setCardHeight(MOBILE_CARD_HEIGHT);
    }
  }, []);

  const onShowMoreItems = () => setVisibleCount(cards.length);

  const calculateColor = (total: number, current: number) => {
    if (total > 3) {
      if (current % 2 === 0) {
        isOdd = !isOdd;
      }
    }
    if (total === 3 && current === 2) {
      isOdd = !isOdd;
    }
    return isOdd;
  };

  return (
    <section className="max-w-none p-0">
      <GridContent data-test="generic-grid-content">
        <SubHeading tag="p" type="lead">
          {subHeading}
        </SubHeading>
        <Heading tag="h2" type="md">
          {heading}
        </Heading>
      </GridContent>

      <Grid>
        <GridRow>
          <GridAuthorable>
            {recipeCarouselMarkUp ? (
              <div itemScope itemType="https://schema.org/ItemList" style={{ display: 'none' }}>
                {cards?.map((card, index) => (
                  <div
                    key={card.id}
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    <span itemProp="position">{index + 1}</span>
                    <span itemProp="url">{`https://${host}${card.link?.url}`}</span>
                  </div>
                ))}
              </div>
            ) : null}
            {cards?.slice(0, visibleCount).map((card: any, index: number) => (
              <GridAuthorableItem
                $total={visibleCount}
                $current={index}
                $isRegularGrid={isRegularGrid}
                key={`grid-authorable-${card.id ?? index}`}
              >
                {card.type === 'authorable' ? (
                  <AuthorableContentCard
                    isAuthorableGrid
                    {...card}
                    height={`${
                      visibleCount > 3 && index === 2 && !isMobile && !isRegularGrid
                        ? cardHeight * 2 + 20
                        : cardHeight
                    }px`}
                    position={index + 1}
                    trackingEvent={{
                      clickAuthorableCard:
                        trackingEvents?.clickAuthorableCard && trackingEvents.clickAuthorableCard,
                      clickOccasionCard:
                        trackingEvents?.clickOccasionCard && trackingEvents.clickOccasionCard,
                    }}
                    isOdd={calculateColor(visibleCount, index)}
                  />
                ) : (
                  <Card
                    isAuthorableGrid
                    data-test={`card-item-${card.id}`}
                    height={`${
                      visibleCount > 3 && index === 2 && !isMobile && !isRegularGrid
                        ? cardHeight * 2 + 20
                        : cardHeight
                    }px`}
                    {...card}
                    trackingEvent={
                      trackingEvents?.clickRecipeCard && trackingEvents.clickRecipeCard
                    }
                    position={index + 1}
                    isOdd={calculateColor(visibleCount, index)}
                  />
                )}
              </GridAuthorableItem>
            ))}
          </GridAuthorable>

          <div className="text-center">
            {visibleCount < cards.length && (
              <button type="button" onClick={onShowMoreItems}>
                <span className="sr-only">{labels?.moreResults}</span>
                <LiquidButton
                  text={labels?.moreResults}
                  height={50}
                  width={200}
                  color={COLORS.primary}
                  textColor={COLORS.white}
                />
              </button>
            )}
          </div>
        </GridRow>
      </Grid>
    </section>
  );
};
